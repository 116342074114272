.paging {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 10px;

  ul {
    li {
      a {
        background-color: #666666;
        border: none;
        color: #495057 !important;
        &.disabled {
          color: #9e9e9e;
          opacity: 0.6;
        }
        &:hover {
          color: #666666 !important;
        }
        &.btn-active {
          background-color: #e9ecef !important;
          color: black !important;
        }
      }

      button:disabled {
        color: #a6a6a6 !important;
      }
    }
  }

  .pagination {
    display: block;
    margin: 0;
  }

  .btn-primary-green1 {
    color: black !important;
  }
}
