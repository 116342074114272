.products {
  display: grid;
  grid-template-columns: repeat(5, 18%);
  gap: 2rem;
}

.icon {
  font-size: 25px;
  cursor: pointer;
  margin: 0px 10px;
}

.absIcon {
  position: absolute;
  right: 0.75rem;
}

.productsDetail {
  color: #a6a6a6;
  margin: 5px 0px;
}

.productsItem {
  position: relative;
  height: 185px;
}

.productsItem img {
  height: 100%;
  border-radius: 3px;
  width: -webkit-fill-available;
  object-fit: cover;
}

.swtichWrap {
  position: absolute;
  top: 5px;
  right: 10px;
}

.searchInput:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #d3d8de !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.productSettings {
  display: grid;
  grid-template-columns: repeat(3, 26%);
  column-gap: 3rem;
  margin-bottom: 30px;
}

.fontBlack {
  color: black;
}

.activeTab {
  background-color: #f3f8fb;
}

.settingIcon {
  font-size: 22px;
  cursor: pointer;
  color: #7b8190;
}

.hoverIcon {
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
}

.addPageIcon {
  font-size: 22px;
  cursor: pointer;
  color: #000000;
}

.newsHeader {
  width: 100%;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  padding: 8px;
  justify-content: end;
  display: none;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99;
  background-color: #00000066;
}

.productCard {
  position: relative;
}

.productCard:hover .newsHeader {
  display: flex;
}

.formSelect > div {
  border: 0;
}

.removeImage {
  display: flex;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
  border-radius: 50%;
  background: white;
  padding: 5px 9px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.xIcon {
  color: #ff0000;
}

.galleryWrapper {
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 300px;
  height: 156px;
  cursor: pointer;
}

.galleryWrapper img {
  width: 100%;
  height: 100%;
}

.galleryWrapper .addPageIcon {
  position: absolute;
  top: 45%;
  left: 50%;
}

.detailsImg {
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 80px;
  height: 50px;
}

.detailsImg img {
  width: 100%;
  height: 100%;
}
