.orders {
  display: grid;
  grid-template-columns: repeat(3, 31%);
  column-gap: 2rem;
}

.icon {
  font-size: 25px;
  cursor: pointer;
  margin: 0px 10px;
}

.viewtxt {
  text-align: end;
}

.absIcon {
  position: absolute;
  right: 0.75rem;
}

.formSelect > div {
  border: 0;
}

.ordersDetail {
  color: #a6a6a6;
}

.downloadicon {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.text1 {
  font-weight: 500;
}

.printIcon {
  margin: 5px 18px;
  width: 30px;
  height: auto;
  cursor: pointer;
}

.custmorDetl {
  display: flex;
  align-items: self-start;
}

.viewordersCard {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.orderContainer p {
  margin: 5px 0px;
}

.mb5 {
  margin-bottom: 15px;
}

.productDetail {
  padding-right: 50px;
  margin-bottom: 50px;
}

.prodDtlContainer {
  display: flex;
  justify-content: space-between;
}
