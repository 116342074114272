.job{
    font-family: inherit;
    font-size: 1.2rem;
    box-shadow: var(--shadow-lg);
    padding:12px;
    border-radius: 5%;
    background-color: rgb(214, 212, 212);

}
.job__top{
    display: flex;
    flex-direction: column;
    gap:1px;
}

.underline{
    width:100%;
    height: 1px;
    border:.2px solid rgb(244, 244, 244);
  }

.job__title{
    font-weight: 700;
    font-size:2rem;
}

.job__city{
    color:grey;
}

.job__bottom{
    margin-top:1rem;
    font-size:1.4rem;
}

.bottom__top{
    display: flex;
    justify-content: space-between;
}
.designation{
    font-weight: 700;
    color:blue

}

.salary{
    color:red;
}

.center{
    display: flex;
    justify-content: space-between;
}

.cta{
    display: flex;
    gap:2rem;
}
.delete{
    color:rgb(255, 0, 157);
    padding:.2rem;
    outline: none;
    cursor: pointer;
}
.edit{
    color:rgb(0, 60, 128);
    padding:.2rem;
    outline: none;
}