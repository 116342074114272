$chip-y-spacing: 15px;
$chip-x-spacing: 5px;
$chip-button-width: $chip-y-spacing + $chip-x-spacing * 2;
$chip-border-radius: 15px;
$chip-background: #f0f0f0;
$chip-color: rgba(0, 0, 0, 0.6);
$chip-min-height: 30px;
$body-bg: #f5f6f8;

body {
  // font-family: "Poppins", sans-serif;
  background-color: $body-bg;
}
#title {
  letter-spacing: 1px;
}

.chips {
  font-size: 14px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 4px;

  &.disableDiv {
    background: #f1f1f1;
    cursor: auto;
    input {
      background: transparent;
      cursor: auto;
    }
  }
}

.chips-input {
  //   display: inline-block;
  //   width: 100%;
  //   // min-height: $chip-min-height;
  //   // margin-bottom: $chip-x-spacing;
  //   // margin-left: $chip-x-spacing * 2;
  //   border: 0;
  //   outline: none;
  //   padding: 7px 10px;
  //   background: inherit;
}

.chip {
  display: inline-block;
  margin-top: $chip-x-spacing;
  margin-bottom: $chip-x-spacing;
  margin-left: $chip-x-spacing;
  margin-right: $chip-button-width;
  position: relative;

  .chip-value {
    display: inline-block;
    padding: $chip-x-spacing $chip-y-spacing / 2 $chip-x-spacing $chip-y-spacing;
    /*padding-left: $chip-y-spacing;
    padding-right: $chip-y-spacing / 2;*/
    background: $chip-background;
    color: $chip-color;
    font-weight: normal;
    border-radius: $chip-border-radius 0 0 $chip-border-radius;
  }

  .chip-delete-button {
    background: $chip-background;
    padding: 0 12px;
    color: $chip-color;
    border: 0;
    border-radius: 0 $chip-border-radius $chip-border-radius 0;
    padding: $chip-x-spacing $chip-x-spacing * 2;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -$chip-button-width;
    line-height: 0.5;
    font-weight: bold;
  }
}
.auto-suggest {
  border: 1px solid #ddd;
  border-top: none;
  position: absolute;
  width: 100%;
  display: none;
  background: #fff;
  overflow: auto;
  max-height: 200px;
  z-index: 1000;
  ul {
    margin: 0 !important;
    list-style: none;
    padding-left: 0;
  }
  li {
    background: rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #ddd;
    padding: 8px 10px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  img {
    margin-right: 10px;
    height: 30px;
  }
  &.show {
    display: block;
  }
}
.chip {
  &.single-chips {
    margin: 0;
    padding: 11px 10px 10px;
    border-radius: 3px;
    border: none;
    background: white;
    font-size: 12px;
    color: black;
    width: 100%;
    .chip-delete-button {
      background: transparent !important;
      right: 0 !important;
      bottom: 4px;
      border-radius: 3px !important;
      font-size: 16px;
      font-weight: 600;
      &:focus {
        outline: 0;
      }
    }
    .chip-value {
      background-color: transparent !important;
      color: black !important;
      padding: 0 !important;
    }
  }
}
