.header {
  grid-column: sidebar-start/ full-end;
  background-color: var(--clr-400);
  color: var(--clr-white);
  font-family: inherit;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.profile {
  position: absolute;
  inset: 0px 0px auto auto;
  transform: translate(0px, 27px);
}
