.leads {
  display: grid;
  grid-template-columns: repeat(3, 31%);
  column-gap: 2rem;
}

.icon {
  font-size: 25px;
  cursor: pointer;
  margin: 0px 10px;
}

.absIcon {
  position: absolute;
  right: 0.75rem;
}

.formSelect > div {
  border: 0;
}

.leadsDetail {
  color: #a6a6a6;
}
