.fontSize {
  font-size: 16px;
  font-weight: 600;
}

.titleItem {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  font-weight: 600;
}

.bodyItem {
  max-height: 65vh;
  overflow-y: auto;
}

.footerItem {
  padding-top: 20px;
}

.floatRight {
  float: right;
}

.secImage {
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.editModal svg {
  font-size: 24px;
  cursor: pointer;
}

.td {
  padding: 10px;
}

.tdInput {
  width: 60%;
  padding: 10px;
}

.xIcon {
  color: #ff0000;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  /* curser-pointer */
}

.xIconTemp {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.popoverContent {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
}

.HeaderPopoverImg {
  margin-right: 10px;
  object-fit: cover;
  object-position: 5%;
}

.modalTable {
  width: 100%;
}

.formSelect > div {
  border: 0;
}

.removeImage {
  display: flex;
  position: absolute;
  top: -15px;
  right: 0px;
  cursor: pointer;
  border-radius: 50%;
  background: white;
  padding: 5px 9px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.modalClass {
  max-width: fit-content !important;
  /* width: 100% !important; */
}

.modalClass1 {
  /* width: fit-content !important; */
  width: 100% !important;
}
.modalBody {
  padding: 0;
  /* margin: 0rem 1rem; */
  padding: 1rem 0rem;
  border-top: 1px solid #dee2e6;
  /* width: fit-content !important; */
  border-bottom: 1px solid #dee2e6;
}

.colorWrapClass {
  max-width: 60px;
  min-width: 60px;
}

.bgColorWrapClass {
  max-width: 160px;
  min-width: 160px;
}

.fontWrapClass {
  max-width: 200px;
  min-width: 200px;
}

.linkWrapFooter1 {
  max-width: 800px;
  min-width: 800px;
}

.linkWrapClass {
  max-width: 400px;
  min-width: 400px;
}

.prevWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

/* 360 style */
.threeSixtyWrapper :not(:defined) > * {
  display: none;
}

.threeSixtyWrapper #ar-button {
  /* background-image: url(""); */
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 132px;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #dadce0;
}

.threeSixtyWrapper #ar-button:active {
  background-color: #e8eaed;
}

.threeSixtyWrapper #ar-button:focus {
  outline: none;
}

.threeSixtyWrapper #ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}

.threeSixtyWrapper model-viewer {
  background-color: #eee;
  overflow-x: hidden;
}

.threeSixtyWrapper model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 175px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

.threeSixtyWrapper model-viewer[ar-status="session-started"] > #ar-prompt {
  display: block;
}

.threeSixtyWrapper model-viewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}

.threeSixtyWrapper model-viewer > #ar-failure {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
  display: none;
}

.threeSixtyWrapper model-viewer[ar-tracking="not-tracking"] > #ar-failure {
  display: block;
}

.pageList {
  display: flex;
  cursor: pointer;
}
.accordian .accordinDiv {
  overflow: hidden;
  transition: max-height 0.2s ease-in;
  max-height: 0px;
  margin-bottom: 0;
}
.accordian.activeCls .accordinDiv {
  max-height: 120px;
  transition: max-height 0.2s ease-in;
  margin-bottom: 0.5rem;
}
.activeCls .downIcon {
  transform: rotate(-180deg);
  transform-origin: center center;
  transition: transform 0.2s ease-in-out;
}
.downIcon {
  transform: rotate(0deg);
  transform-origin: center center;
  transition: transform 0.2s ease-in-out;
}

.borderBottom {
  border-bottom: 1px solid #dee2e6;
}
