.fs14 {
  font-size: 14px;
}

.fontD {
  width: 260px !important;
}

.active-menu {
  background-color: #69b5df !important;
  color: white;
}

.d-menu-item {
}

.fit-content {
  width: fit-content;
}

.d-item {
  width: fit-content;
  border-radius: 2px;
}
.d-item:hover {
  background-color: rgba(105, 181, 223, 0.4);
}

.font-family-d {
  /* width: 400px; */
}

.search-icon {
}

.search-icon {
  position: absolute;
  right: 20px;
  top: 4px;
}
.search-wrapper {
  position: relative;
}
.search-wrapper > input {
  position: relative;
}

.section-list {
  max-width: 90vw !important;
}
