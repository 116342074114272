@import "./theme.scss";
@import "./mixin.scss";

// .pdrightbar{
//     padding-bottom: 0px;
// }
body.uploaderLayout {
  &,
  .bgwhite {
    min-height: auto;
  }
  .table-layout {
    height: auto;
    .pdrightbar {
      @include mobile {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .profile-botspacing {
      .btn-sm {
        font-size: 12px !important;
      }
      @include mobile {
        padding-bottom: 0;
      }
    }
  }
  @include desktop {
    background: $white-color;
  }
  @include mobile {
    background: $black-color1;
  }

  .pdrightbar {
    padding: 0;
  }
}
/*.table-layout,.h100vh{
    height: auto!important;
   
}*/
.rose-bowl-uploader {
  background: $white-color;
  .main-panel {
    width: 100%;
    .content {
      padding: 0;
    }
  }
}
.container {
  &.uploader {
    background: $black-color1;
    @include desktop {
      margin: 0 -15px;
      width: calc(100% + 30px);
    }
  }
}
.photoUploader {
  padding-bottom: 10px;
  @include desktop {
    background: $white-color;
    transform: translateX(-50%);
    @include position(absolute, $left: 50%);
  }
  @include mobile {
    min-height: initial !important;
  }
  .txt-photo {
    color: $black-color1;
  }
  .btn-close {
    transform: rotate(45deg);
  }
  .style-upload {
    border: 1px solid $gray-color4;
    width: 200px;
    text-align: center;
    cursor: pointer;
    background: $white-color;
    .icon-plus {
      color: #d01d15;
      font-size: 5em;
      line-height: initial;
      padding: 0 0 21px;
      font-weight: bold;
      @include mobile {
        font-size: 4em;
      }
    }
    .txt-fot {
      font-size: 12px;
      border-top: 1px solid $gray-color4;
      padding: 6px 0 4px;
    }
    @include mobile {
      margin: 0 auto;
    }
  }
  .crop-txt {
    font: 14px arial;
    margin: 7px 0;
    @include mobile {
      color: $white-color;
      font-size: 12px;
    }
  }

  @include desktop {
    .panelcroparea {
      width: 700px;
      margin: 0 auto;
    }
  }

  .cropper {
    @include desktop {
      background: $gray-color8;
      width: 700px;
      height: 350px;
      margin: 0px auto 5px;
    }
    @include mobile {
      margin: 3px auto 9px;
      width: 100%;
      height: 250px;
    }
  }

  .upload-tag {
    @include desktop {
      display: flex;
    }
    @include mobile {
      margin: 10px 0 !important;
    }
    .upload-textbox {
      display: inline-block;
      width: calc(100% - 15px);
    }
    input {
      display: block;
      color: #000000;
      font: 14px arial;
      width: 100%;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      resize: none;
      height: 30px;
      border-radius: 3px 0 0 3px;
      @include mobile {
        border-radius: 3px;
      }
    }
    .tag-btn {
      width: 100px;
      border-radius: 0 3px 3px 0;
      @include mobile {
        margin-left: 10px;
        border-radius: 3px;
        width: 80px;
      }
    }
    .tagged {
      border-color: $blue-color5 !important;
      background-color: $blue-color5 !important;
      color: $white-color !important;
      @include mobile {
        border-color: $white-color !important;
        background-color: $white-color !important;
        color: $yellow-color !important;
      }
    }
  }

  .btn-back {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .loader-wrapper {
    @include desktop {
      display: flex;
    }
    .img-thumb-panel {
      @include desktop {
        margin: 25px 20px 0 0;
        width: 200px;
        @include position(relative);
        height: 100%;
      }

      @include mobile {
        //padding: 0 10px;
      }

      .scrollouter {
        @include mobile {
          white-space: nowrap;
        }
      }
      .img-thumb {
        width: 195px;
        display: inline-block;
        margin-bottom: 5px;
        @include position(relative);
        border: 2px solid $white-color;
        border-radius: 5px;
        overflow: hidden;
        .icon-fot {
          i {
            @include position(absolute, $bottom: 5px, $right: 5px);
            color: #e5e5e5;
            font-size: 20px;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.8);
            border-radius: 50px;
            cursor: pointer;
          }
        }
        @include mobile {
          border-color: $black-color1;
          width: 145px;
          margin-bottom: 0;
          .image-style img {
            min-height: 90px;
          }
        }
        &.active {
          border: 2px solid $blue-color5;
        }
      }
      @include desktop {
        .slick-list {
          .slick-track {
            height: auto !important;
          }
        }
        .slick-arrow {
          @include position(absolute, $left: 50%);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          display: inline-block;
          left: 50%;
          transform: translateX(-50%);
          &:before {
            display: none;
          }
        }
        .slick-prev {
          @extend .slick-arrow;
          border-bottom: 12px solid $blue-color5;
          top: -25px;
        }
        .slick-next {
          @extend .slick-arrow;
          border-top: 12px solid $blue-color5;
          top: 105%;
          transform: translate(-50%, -100%);
        }
      }
    }
  }

  .uploadtxt {
    color: $gray-color;
    font-size: 12px;
    text-transform: uppercase;
    margin-right: 10px;
    @include mobile {
      font-size: 10px;
    }
  }

  .btn-cropper-section {
    display: flex;
    @include mobile {
      overflow: auto;
    }
    a {
      background: $gray-color8;
      color: $white-color;
      font-size: 15px;
      flex-grow: 1;
      margin: 0 3px;
      text-align: center;
      padding: 7px;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      border-radius: 3px;
      &:hover,
      &.active {
        background: $info;
        text-decoration: none;
      }
      @include mobile {
        font-size: 12px;
        padding: 10px 0 8px;
        line-height: initial;
      }
    }
  }

  .savebtn {
    background: $blue-color5 !important;
    border-color: $blue-color5;
    &:hover {
      border-color: $blue-color5;
    }
    @include mobile {
      margin-top: 10px;
      //margin: 20px;
      //transform: translateX(-50%);
      //@include position(relative, $left: 27%);
    }
  }
  .title_photouploader {
    @include desktop {
      padding: 10px 0 20px;
      display: flex;
    }
    .txt-kalakalphoto {
      font-size: 16px;
      color: $black-color;
    }
    .back-btn {
      display: none;
    }
    @include mobile {
      margin: 0 -5px;
      .txt-kalakalphoto {
        letter-spacing: 2px;
        font: 12px arial;
        text-align: center;
        padding: 8px 14px;
        background-color: $gray-color8;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0 -10px 10px -10px;
        display: flex;
        justify-content: space-between;
      }
      .back-btn {
        display: block;
        margin-left: 10px;
        letter-spacing: 2px;
        font: 12px "arial";
        color: $white-color;
      }
    }
    .batch-upload {
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      @include mobile {
        color: $gray-color;
        font-size: 13px;
        > span {
          display: block;
        }
      }
      a {
        color: $gray-color;
        padding: 0 8px;
        text-transform: capitalize;
        i {
          margin-right: 5px;
          color: $blue-color5;
          @include mobile {
            color: $white-color;
          }
        }
      }
    }
  }
}
.batch-social-upload {
  @include desktop {
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
  }
  position: relative;
  &:before {
    //content: 'Or';
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -38px;
    font-size: 13px;
    margin: 10px;
  }
  p {
    font-size: 16px;
    color: $black-color3;
    margin-right: 20px;
  }
  .font-awsome {
    position: absolute;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Brands";
    left: 0px;
    top: -1px;
  }
  .social-btn-txt {
    position: relative;
    padding: 0 15px 0 18px;
    background: none;
    color: $gray-color8;
    width: auto;
    font-size: 15px;
    border: 0px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    line-height: 17px;
  }
  .insta-btn {
    background: radial-gradient(circle at 30% 110%, #fd5949 5%, #d6249f 90%);
    border: none;
    padding: 7px 20px;
  }
  .utube-btn {
    &.active {
      background-color: $white-color !important;
      border: 1px solid #c6c6c6 !important;
      color: $blue-color5 !important;
    }
    &.social-btnactive {
      background-color: $white-color !important;
      border: 1px solid #c6c6c6 !important;
      color: $blue-color5 !important;
    }
  }

  .w20 {
    width: 20%;
  }
  .batch-instagram-btn {
    //@include mobile{display: inline-block}
    a {
      // @extend .social-btn-txt;
      color: $white-color;
      text-decoration: none;
      &:before {
        //content: "\f16d";
        @extend .font-awsome;
      }
    }
  }
  .batch-facebook-btn {
    @include mobile {
      display: inline-block;
    }
    button {
      @include mobile {
        margin: auto;
        span {
          font-size: 14px !important;
        }
      }
      margin-right: 20px;
      padding: 7px 20px;
      -webkit-font-smoothing: antialiased;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: calc(0.27548vw + 12.71074px);
      text-decoration: none;
      transition: background-color 0.3s, border-color 0.3s;
      background-color: #4c69ba;
      border: calc(0.06887vw + 0.67769px) solid #4c69ba;
    }
  }
  @include mobile {
    .social-btn {
      width: 100%;
      margin: 5px auto;
      font-size: 14px !important;
    }
  }
  @media screen and (max-width: 1599px) {
    display: inline-block;
    > div {
      width: 60%;
      margin-top: 5px;
    }
    .social-btn {
      width: 250px;
    }
    .utube-btn {
      width: 250px;
      margin-top: 5px;
      font-size: 12px;
    }
  }
}

.popup-heading {
  border-bottom: 1px solid #ccc;
  padding: 5px;
  display: flex;
  margin-top: -35px;
  margin-bottom: 15px;
  text-transform: uppercase;
  .txt {
    font-size: 15px;
    color: $black-color3;
  }
  a {
    margin-left: auto;
    font-size: 13px;
    color: $black-color3;
    &.btn-close {
      right: 20px;
    }
  }
}
.listphoto.batch-uploader {
  .photo-frame-aspect {
    width: 22%;
    cursor: pointer;
    &:nth-child(4n) {
      margin-right: 0px;
    }
    &.active {
      border: 2px solid $red-color;
    }
  }
}

.cropper-container {
  font-size: 0;
  line-height: 0;

  position: relative;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;

  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}

.cropper-container img {
  /* Avoid margin top issue (Occur only when margin-top <= -height) */
  display: block;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100%;
  height: 100%;
  image-orientation: 0deg;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cropper-wrap-box {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}

.cropper-modal {
  opacity: 0.5;
  background-color: #000;
}

.cropper-view-box {
  display: block;
  overflow: hidden;

  width: 100%;
  height: 100%;

  outline: 1px solid $blue-color5;
  outline-color: rgba(14, 18, 223, 0.75);
}

.cropper-dashed {
  position: absolute;

  display: block;

  opacity: 0.5;
  border: 0 dashed #eee;
}

.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}

.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;

  width: 0;
  height: 0;

  opacity: 0.75;
}

.cropper-center:before,
.cropper-center:after {
  position: absolute;
  display: block;
  content: " ";
  background-color: #eee;
}

.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}

.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;

  display: block;

  width: 100%;
  height: 100%;

  opacity: 0.1;
}

.cropper-face {
  top: 0;
  left: 0;

  background-color: #fff;
}

.cropper-line {
  background-color: $blue-color5;
}

.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}

.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}

.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}

.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}

.cropper-point {
  width: 5px;
  height: 5px;

  opacity: 0.75;
  background-color: $blue-color5;
}

.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}

.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}

.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}

.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}

.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}

.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}

.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}

.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
  }
}

.cropper-point.point-se:before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: " ";
  opacity: 0;
  background-color: $blue-color5;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-hide {
  position: absolute;

  display: block;

  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
