//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

// .btn-dark,
// .btn-secondary {
//   color: $gray-200;
// }

.btn-outline-light {
  color: $gray-900;
}

// btn-soft-color

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    background-color: rgba(($value), 0.25);
    border-color: rgba(($value), 0.25);
    color: $value;
  }
}

.btn-group-vertical {
  label {
    margin-bottom: 0;
  }
}

// .btn {
//   display: inline-block;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #495057;
//   text-align: center;
//   vertical-align: middle;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   background-color: transparent;
//   border: 1px solid transparent;
//   padding: 0.47rem 0.75rem;
//   font-size: 0.9rem;
//   border-radius: 0.25rem;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

// .btn-primary {
//   color: #fff;
//   background-color: #2a2c3b;
//   border-color: #2a2c3b;
// }

// .btn-outline {
//   background-color: #fff;
//   color: #2a2c3b;
//   border-color: #2a2c3b;
// }
