.landingContainer {
  display: grid;
  grid-template-rows: 100vh;
  grid-template-columns: repeat(2, 1fr);
  background-color: #fff;
}

.landingContainer_video {
  grid-column: 1 / 2;
  grid-row: 1/-1;
  /* background-color: var(--clr-400); */
  background-image: url("../../assets/images/landing-img.png");
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landingContainer_login {
  display: flex;
  justify-content: center;
  align-items: center;
}
