.sidebar {
  grid-column: sidebar-start/sidebar-end;
  background-color: var(--clr-400);
  height: 100vh;
  overflow-y: hidden;
}

.sidebar ul {
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  font-size: 1.6rem;
}

.sidebar__links {
  display: flex;
  gap: 1rem;
  cursor: pointer;
  align-items: center;
  color: #a6a6a6;
  padding: 0 3px 0 15px;
}

.sidebar__link {
  color: #a6a6a6;
}

/* .sidebar__links:hover,
.sidebar__link:hover {
  color: var(--clr-white);
  background-color: grey;
} */

.popoverContent {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
}

.sidebarLogo {
  height: 50px;
  width: 50px;
  margin: auto;
}

.logoTxt {
  font-size: 22px;
  color: #555b6d;
  font-weight: 700;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userName {
  bottom: 0px;
  display: flex;
  align-items: center;
  padding: 21px;
  color: #a6a6a6;
  position: relative;
  font-size: 15px;
  font-weight: 500;
}
