.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 65px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  top: 30px;
  left: -50%;
}
.hover-text:hover .tooltip-text {
  visibility: visible;
}
.hover-text {
  position: relative;
  display: inline-block;
  font-family: Avenir;
  text-align: center;
}
