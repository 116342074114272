.react-tagsinput .react-tagsinput-tag {
  align-items: center;
  background: #edf2f7;
  border-radius: 0.375rem;
  display: inline-flex;
  justify-content: center;
  padding: 0.15rem 0.5rem;
  font-size: initial;
  color: initial;
  border: none;
}
.react-tagsinput .react-tagsinput-remove {
  background: none;
  border: 0;
  border-radius: 50%;
  line-height: inherit;
  padding: 0 0.5rem;
}

.react-tagsinput .react-tagsinput-input {
  font-size: initial;
}
.react-tagsinput {
  display: block;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-tagsinput--focused {
  display: block;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
