//
// _dropdown.scss
//

// The dropdown wrapper (`<div>`)
.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
  // Generate the caret automatically
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y $dropdown-padding-x;
  margin: 0; // Override default margin of ul
  font-size: 0.9rem;
  color: $dropdown-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);

  &[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: $dropdown-spacer;
  }
}

// scss-docs-start responsive-breakpoints
// We deliberately hardcode the `bs-` prefix because we check
// this custom property in JS to determine Popper's positioning

// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//     .dropdown-menu#{$infix}-start {
//       --bs-position: start;

//       &[data-bs-popper] {
//         right: auto;
//         left: 0;
//       }
//     }

//     .dropdown-menu#{$infix}-end {
//       --bs-position: end;

//       &[data-bs-popper] {
//         right: 0;
//         left: auto;
//       }
//     }
//   }
// }
// scss-docs-end responsive-breakpoints

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }
}

.dropend {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    &::after {
      vertical-align: 0;
    }
  }
}

.dropstart {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    &::before {
      vertical-align: 0;
    }
  }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  height: 0;
  margin: $dropdown-divider-margin-y 0;
  overflow: hidden;
  border-top: 1px solid $dropdown-divider-bg;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: #7b8190;
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703
  @if $dropdown-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius);
    }
  }

  &:hover,
  &:focus {
    color: $dropdown-link-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    // @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    // @include gradient-bg($dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
  }
}

.dropdown-menu.show {
  display: block;
  border: 1px solid #efefef;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-header-padding;
  margin-bottom: 0; // for use with heading elements
  //   @include font-size($font-size-sm);
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

// Dark dropdowns
.dropdown-menu-dark {
  color: $dropdown-dark-color;
  background-color: $dropdown-dark-bg;
  border-color: $dropdown-dark-border-color;
  //   @include box-shadow($dropdown-dark-box-shadow);

  .dropdown-item {
    color: $dropdown-dark-link-color;

    &:hover,
    &:focus {
      color: $dropdown-dark-link-hover-color;
      //   @include gradient-bg($dropdown-dark-link-hover-bg);
    }

    &.active,
    &:active {
      color: $dropdown-dark-link-active-color;
      //   @include gradient-bg($dropdown-dark-link-active-bg);
    }

    &.disabled,
    &:disabled {
      color: $dropdown-dark-link-disabled-color;
    }
  }

  .dropdown-divider {
    border-color: $dropdown-dark-divider-bg;
  }

  .dropdown-item-text {
    color: $dropdown-dark-link-color;
  }

  .dropdown-header {
    color: $dropdown-dark-header-color;
  }
}

// .dropdown-menu {
//   box-shadow: $box-shadow;
//   animation-name: DropDownSlide;
//   animation-duration: 0.3s;
//   animation-fill-mode: both;
//   margin: 0;
//   position: absolute;
//   z-index: 1000;
//   &.show {
//     top: 100% !important;
//   }

//   // &[style] {
//   //   right: auto!important;
//   //   left: 0;
//   // }
// }

// .dropdown-menu {
//   left: 0;
//   margin-top: 0.125rem;
// }
// .dropdown-menu-end {
//   left: auto;
//   right: 0;
// }

// .dropdown-menu[data-popper-placement^="right"],
// .dropdown-menu[data-popper-placement^="top"],
// .dropdown-menu[data-popper-placement^="left"] {
//   top: auto !important;
//   animation: none !important;
// }

// @keyframes DropDownSlide {
//   100% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//   }
//   0% {
//     -webkit-transform: translateY(10px);
//     transform: translateY(10px);
//   }
// }

// @media (min-width: 600px) {
//   .dropdown-menu-lg {
//     width: 320px;
//   }

//   .dropdown-menu-md {
//     width: 240px;
//   }
// }

// .dropdown-divider {
//   border-top-color: $border-color;
// }

// // Dropdown size

// .dropdown-mega-menu-xl {
//   width: 40rem;
// }

// .dropdown-mega-menu-lg {
//   width: 26rem;
// }

// @media (min-width: 992px) {
//   .dropdown-menu-lg-start {
//     right: auto;
//     left: 0;
//   }
//   .dropdown-menu-lg-end {
//     right: 0 !important;
//     left: auto !important;
//   }
// }
