@import "theme.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h4 {
  font-size: 22px;
  font-weight: 600;
}
.left-content {
  // width: 410px;
  width: 50%;
}
.dis-content {
  display: contents;
}

.event_type .form-check {
  display: inline-block;
  padding-right: 10px;
}

.mbi-0 {
  margin-bottom: 0 !important;
}

.user-search {
  width: 300px;
  max-width: 300px;
}
.w-250 {
  width: 250px;
}

.custom-table th {
  background-color: #f2f2f1 !important;
  border-bottom-color: #f2f2f1 !important;
  border: none !important;
  box-shadow: none !important;
  position: sticky;
  top: 0;
  padding: 8px;
  z-index: 999;
}
.bg-transferent {
  background-color: transparent;
}

.bg-main {
  background-color: $body-bg;
}

.custom-table td {
  border: none !important;
}

.modal-card-width {
  & {
    width: 800px !important;
    max-width: 800px !important;
  }
}

.w100 {
  width: 100px;
}

.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}

.bg-gray-300 {
  background-color: #a6a6a6;
}

.comment-check-icon {
  font-size: 21px;
  color: green;
}
.comment-remove-icon {
  font-size: 21px;
  color: red;
}

.news-card:hover .news-header {
  display: flex;
}
.news-header {
  width: 100%;

  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  padding: 8px;
  justify-content: end;
  display: none;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.line-clamp-one {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.image-style {
  position: relative;
  background-size: contain;
  background-position: center center !important;
  background-repeat: no-repeat;
  // background-color: #aaaaaa;
  overflow: hidden;
}

.image-style-participate {
  background-size: cover;
  background-position: center center !important;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 130px;
  height: 150px;
}

.magazine {
  width: 100%;
}

.image-style img {
  display: block;
  min-height: 100px;
}

.card-hover-show {
  display: none;
}

.hover-card {
  cursor: pointer;
}
.hover-card:hover {
  .card-hover-show {
    display: block;
  }
}

// profession popup

.popup-myprofile {
  width: 800px;
}
.popup-myprofile .lable_form {
  margin-bottom: 10px;
}
.popup-myprofile .auto-suggest {
  z-index: 1;
}
.popup-myprofile .chips .chips-input {
  padding: 8px 10px;
  margin: 0;
}
.popup-myprofile .search_con_wrapper {
  padding: 0;
  min-height: initial;
}
.popup-myprofile ul.search_keywords {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.popup-myprofile ul.search_keywords li {
  margin-right: 10px;
  width: 100%;
}
.popup-myprofile ul.search_keywords li:last-child {
  margin-right: 0;
}
.popup-myprofile .search-blackbox {
  background: $gray-300;
  font-size: 13px;
  display: block;
  padding: 8px 0 7px;
  color: #ffffff;
  text-align: center;
  position: relative;
}
.popup-myprofile li.active .search-blackbox:before {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(255, 255, 255, 0.7);
  left: calc(50% - 3px);
}
.popup-myprofile .search-blackbox:hover {
  background: #666;
}
.popup-myprofile .search_desc {
  position: static;
  max-height: 300px;
  min-height: 300px;
  background: $body-bg;
  padding: 15px;
  display: flex;
  width: 100%;
  z-index: 1;
}
.popup-myprofile .search_desc .search_dd_cat {
  width: 100%;
  padding-bottom: 10px;
}
.popup-myprofile .search_desc .title_sdc a {
  text-decoration: none;
  cursor: default;
  word-wrap: break-word;
  display: block;
  font-size: 12px;
  font-weight: bold;
}
.popup-myprofile .search_desc .search_dd_cat ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.popup-myprofile .search_desc .search_dd_cat ul li {
  font-size: 11px;
  color: #000000;
  padding: 2px 15px 2px 0;
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
}

.break-all {
  word-break: break-all;
}

.tag_style {
  background-color: $gray-300;
  color: white !important;
  padding: 6px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}
.tag-border-style.active-tag {
  background-color: $gray-300 !important;
  color: white;
}

.tag-border-style {
  border: 1px solid $gray-300;
  background-color: transparent !important;
  color: $gray-300;
  padding: 6px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}

/* edit Event */

.medialist {
  display: flex;
  flex-wrap: wrap;
}
.medialist .edit-prof {
  z-index: 1;
  color: #ffffff;
}
.medialist .i_close_aligntop {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
}

.video-frame-maxwidth {
  // max-width: 300px;
  width: calc(100% / 5);
}
.video-event {
  // max-width: 300px;
  width: calc(100% / 3) !important;
}
.video-frame-maxwidth .mediaVideothumb {
  border-radius: 3px 3px 0 0 !important;
  overflow: hidden;
  position: relative;
}
.mediaVideothumb::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.mediaVideothumb .playicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #ffffff;
  font-size: 48px;
}

.i_close {
  position: relative;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.i_close:after {
  display: block;
  background: #ffffff;
  position: absolute;
  content: "";
}
.i_close:before {
  display: block;
  background: #ffffff;
  position: absolute;
  content: "";
}
.i_close:after {
  width: 12px;
  height: 2px;
  left: 0;
  top: 5px;
}
.i_close:before {
  height: 12px;
  width: 2px;
  top: 0;
  left: 5px;
}
.photo-close-icon:before {
  height: 12px;
  width: 2px;
  background: #ffffff;
}

.text-gray {
  color: $gray-600;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.w-600 {
  font-weight: 600;
}
.bottom-shadow {
  color: white;
  position: absolute;
  bottom: 0px;
  z-index: 2;
  padding: 9px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ) !important;
}

.fit-content {
  width: fit-content;
}

.absolute-right {
  position: absolute;
  top: 10px;
  right: 12px;
}

.sections {
  border: 1px solid transparent;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.sections:hover {
  border: 1px solid $gray-200;
}
.active-borderd {
  border: 1px solid $gray-200;
}

input.rc-time-picker-input {
  display: block;
  width: 100%;
  height: auto;
  padding: 8px 24px 8px 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input.rc-time-picker-input:focus {
  outline: none;
}
.rc-time-picker-clear-icon:after {
  position: absolute;
  right: 5px;
  top: 50%;
  z-index: 4;
}

.selectedmember-container {
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.header-bar {
  display: flex;
}
p.heading {
  color: #000 !important;
  font-size: 1.2em !important;
  font-weight: 600 !important;
}
.heading-main {
  font-size: 22px;
  font-weight: 800;
  color: black;
}
.remove-margin-input input {
  margin: 0 !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 100% !important;
}
.remove-margin-input .btn {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.remove-margin-input {
  margin-left: 40px;
}
.main-section {
  color: black;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.member-list {
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
}
.member-container {
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-item input,
  .list-item span {
    margin-right: 25px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .list-item-select:not(:last-child) {
    margin-bottom: 10px;
  }
}
.h-70vh {
  height: 75vh !important;
}

.btn,
.btn:hover {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  text-align: start;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:disabled,
.btn-success,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.btn-success:disabled,
.btn-secondary,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:disabled {
  color: #495057 !important;
}

.btn-danger,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger:disabled {
  color: #dc3545 !important;
}

.btn-gray {
  background-color: #6f6f6f !important;
  color: white !important;
}

.btn-gray:hover {
  color: white;
}

.login-section-btn,
.login-section-btn:active,
.login-section-btn:focus,
.login-section-btn:hover,
.login-section-btn:disabled {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  text-align: center !important;
  font-size: 1rem !important;
  font-weight: 400;
}

.absolute-top-right {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99;
}

// form-check-input
.card-absolute-header {
  background-color: rgba(42, 44, 59, 1);
}
.card-absolute-header .form-check-input {
  background-color: white;
}

.card-absolute-header .form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%3e91b5'/%3e%3c/svg%3e");
}

.card-absolute-header i {
  color: white;
}

.bg-light-gray {
  background: #eeeeee !important;
}
.icon-color {
  color: #9a9b9d;
}

.nocaps {
  text-transform: none !important;
}

.bg-gray {
  background-color: #88898f;
}

.outline-none {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.images-uploader .photoUploader {
  position: relative;
  transition: none;
}

.image-upload-progrees {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 26px;
  left: 0;
}

.progress {
  width: 100%;
  margin-bottom: 0;
  height: 20px !important;
}
.progress-bar-pos {
  position: fixed;
  bottom: 30px;
  width: calc(100% - 22%);
  padding: 15px 0;
  z-index: 5;
  background: #fafafa;
}
.w-15 {
  width: calc(100% / 5);
}

.page-item {
  margin: 0px 6px;
}

.first-item {
  margin-right: 6px;
}

.last-item {
  margin-left: 6px;
}

// .modal {
//   left: 135px;
// }

.w-500px {
  width: 500px;
}

input[readonly] {
  background-color: transparent !important;
}

.add-contributor-btn {
  position: absolute;
  top: 13px;
  right: 26px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.about-logo {
  height: 120px !important;
  width: 120px !important;
}

.contribution-radio {
  display: flex;
  flex-direction: row-reverse;
}
.contribution-radio label {
  margin-left: 12px;
  width: 150px;
}

.clogo-bloc {
  width: 205px !important;
  height: 206px !important;
}

.dot-status {
  border-radius: 100%;
  height: 15px;
  width: 15px;
}

.status-dot-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.pi-0 {
  padding: 0 !important;
}

.message-bg {
  background-color: #e9ecef;
  border-radius: 10px;
}

.drawer-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 30;
  background-color: white;
  width: 0px;
  transition: all 0.3s;
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}

.drawer-body {
  margin-top: 60px !important;
}
.drawer-header {
  position: fixed;
  width: 0px;
  top: 0;
  z-index: 40;
  height: 55px;
  padding-left: 15px;
}

.drawer-wrapper.open {
  width: 450px;
  display: block;
  .drawer-header {
    width: 450px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.text-center {
  text-align: center !important;
}

.position-relative {
  position: relative !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: flex-end !important;
}

.margin-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-2 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mx-3 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.my-3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.my-4 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 16px !important;
}
.ml-4 {
  margin-left: 24px !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-3 {
  margin-right: 16px !important;
}
.mr-4 {
  margin-right: 24px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-3 {
  margin-bottom: 16px !important;
}
.mb-4 {
  margin-bottom: 24px !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-3 {
  margin-top: 16px !important;
}
.mt-4 {
  margin-top: 24px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-3 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.px-4 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.py-4 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.pb-3 {
  padding-bottom: 12px !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fs20 {
  font-size: 20px !important;
}

.w-1000 {
  width: 1000px;
}

.form-label {
  margin-bottom: 8px;
}

.form-control {
  display: block;
  // width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-error {
  border: 1px solid red;
}

.form-date-picker {
  width: 150px;
}

.form-date-picker:focus {
  box-shadow: none !important;
}

.input-error-message {
  margin-top: 10px;
  // border: 1px solid red;
  color: red;
}

input[type="range" i]:focus-visible {
  outline: none;
}

// switch css
.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a6a6a6;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: grey;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px grey;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }
}
// switch css end

.pointer-none {
  pointer-events: none;
}

.pointer-auto {
  pointer-events: auto;
}

.rot180 {
  transform: rotate(180deg);
}

.zIndex1051 {
  z-index: 1051;
}

.custom-select__single-value {
  text-transform: none !important;
}

.custom-select__single-value > div {
  max-height: 30px !important;
}

// quill css
.ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.ql-container {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 150px;
  overflow-y: scroll;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

// image-cropper
.image-cropper img {
  height: auto;
  max-height: 500px;
  width: 100%;
  min-width: 400px;
}
