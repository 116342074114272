.iframeContainer {
  display: flex;
  height: 100%;
  width: 100%;
}

iframe {
  height: 100%;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.buttonWrapper svg {
  margin: 15px 0px;
  font-size: 20px;
  cursor: pointer;
}

.HeaderPopoverImg {
  margin-right: 10px;
  object-fit: cover;
  object-position: 5%;
}
