.login {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.login_form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.login_row {
  display: flex;
  flex-direction: column;
}

.login_input {
  display: flex;
  gap: 2rem;
}

.login_title {
  font-size: 1.8rem;
  font-weight: 700;
}

.login_label {
  font-size: 1.6rem;
  font-family: inherit;
}

.login_input {
  font-size: 1.2rem;
  /* width:60%; */
  padding: 0.2rem;
  font-size: 2rem;
}
/* ====================== */
.position_relative {
  position: relative !important;
}

.value_container {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form_control {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-width: 1px;
  border-style: solid;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown_container {
  position: absolute;
  top: 110%;
  background-color: #fff;
  min-width: 300px;
  border-radius: 8px;
  max-height: 165px;
  overflow: hidden;
  z-index: 1;
  border: 1px solid #ced4da;
}

.flex_column {
  flex-direction: column !important;
}

.d_flex {
  display: flex !important;
}

.w_100 {
  width: 100% !important;
}

.h_100 {
  height: 100% !important;
}

.overflow_auto {
  overflow: auto !important;
}

.dropdown_container > div > div {
  font-size: 12px;
  padding: 2px 5px;
}

.justify_content_between {
  justify-content: space-between !important;
}

.greenIcon {
  position: absolute;
  right: 0.75rem;
  color: green;
}

.redIcon {
  position: absolute;
  right: 0.75rem;
  color: red;
}

.twoCol {
  grid-template-columns: repeat(2, 50%);
}
