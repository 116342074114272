@import "./theme.scss";

/***Color***/
$yellow-color: #d01d15;
$red-color: #d01d15;
$red2-color: #c1272d;
$red3-color: #98211b;
$red4-color: #ff0000;

$black-color: #000000;

$black-color1: #18191e;
$black-color2: #3e3e3e;
$black-color3: #666666;
$black-color4: #333;
$black-color5: #1b1b1b;
$black-color6: #0d0d0d;
$black-color7: #181818;
$black-color8: #555454;
$black-color9: #202020;
$black-color10: #444444;
$black-color11: #1f1f1f;
$black-color12: #959595;

$gray-color: #787878;
$gray-color1: #e3e3e3;
$gray-color2: #ced4da;
$gray-color3: #e5e5e5;
$gray-color4: #cecfd1;
$gray-color5: #e6e6e6;
$gray-color6: #f1f1f1;
$gray-color7: #999999;
$gray-color8: $primary;
$gray-color9: #dcdddf;
$gray-color10: #d4d4d4;
$gray-color11: #6e6e6e;
$gray-color12: #b2b3b6;
$gray-color13: #b3b3b3;
$gray-color14: #2b2b2b;
$gray-color15: #949599;
$gray-color16: #dee2e6;
$gray-color17: #ccc;
$gray-color18: #939497;
$gray-color19: #878787;
$gray-color20: #bebebe;
$gray-color21: #495055;
$gray-color22: #657786;
$gray-color23: #161617;
$gray-color24: #a3a3a5;
$offwhite: #f9f9f9;
$offwhite1: #f1efef;

$green-color: #009900;
$green-color1: #84b921;
$green-color2: #25d366;
$green-color3: #0c2f1d;
$green-color4: #00ff00;

$blue-color: #39f;
$blue-color1: #475993;
$blue-color2: #092771;
$blue-color3: #5cabfc;
$blue-color4: #0066ff;
$blue-color5: $primary;
$blue-color6: #000034;

$white-color: #ffffff;

/* Device realted variables */
$mobile: 320px;
$tablet: 768px;
$tabletdw1: 481px;
$tabletdw2: 1024px;
$desktop: 992px;
$highResolution: 1200px;

@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: "";
  //z-index: 2;
  border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

@mixin alpha-background-color($color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  background-color: $solid-color;
  background-color: $color;
}

// Vendor prefixing for shorthand properties
@mixin vendor-prefixes($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
}

@mixin bg-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin clearfix() {
  &::before,
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@for $i from 0 through 55 {
  .mt#{$i} {
    margin-top: $i + px;
  }
  .mb#{$i} {
    margin-bottom: $i + px;
  }
  .ml#{$i} {
    margin-left: $i + px;
  }
  .mr#{$i} {
    margin-right: $i + px;
  }

  .p#{$i} {
    padding: $i + px;
  }
  .pt#{$i} {
    padding-top: $i + px;
  }
  .pb#{$i} {
    padding-bottom: $i + px;
  }
  .pl#{$i} {
    padding-left: $i + px;
  }
  .pr#{$i} {
    padding-right: $i + px;
  }
}

@for $i from 0 through 100 {
  .width#{$i} {
    width: $i;
  }
}

@media (min-width: 992px) {
  @for $i from 0 through 55 {
    .mr-lg-#{$i} {
      margin-right: $i + px;
    }
  }
}

@media (max-width: 991px) {
  @for $i from 0 through 55 {
    .mt#{$i}-m {
      margin-top: $i + px;
    }
    .mr#{$i}-m {
      margin-right: $i + px;
    }
    .mb#{$i}-m {
      margin-bottom: $i + px;
    }
    .ml#{$i}-m {
      margin-left: $i + px;
    }
  }
}

@media (min-width: 992px) {
  @for $i from 0 through 55 {
    .mt#{$i}-d {
      margin-top: $i + px;
    }
    .mr#{$i}-d {
      margin-right: $i + px;
    }
    .mb#{$i}-d {
      margin-bottom: $i + px;
    }
    .ml#{$i}-d {
      margin-left: $i + px;
    }
  }
}

@for $s from 7 through 28 {
  .fs#{$s} {
    font-size: $s / 16 + rem;
  }
}

//Maintain Aspect Ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .aspect-cont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
//line clamping
@mixin lineclamp($val) {
  display: -webkit-box;
  -webkit-line-clamp: $val;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/******* Media query Mixing *******/
@mixin mobile {
  @media (min-width: #{$mobile}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin onlyMobile {
  @media (min-width: #{$mobile}) and (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin notMobile {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin onlyDesktop {
  @media (min-width: #{$tabletdw2 + 1}) {
    @content;
  }
}
@mixin tabletLandscape {
  @media only screen and (min-width: #{$tabletdw1}) and (max-width: #{$tabletdw2}) and (orientation:landscape) {
    @content;
  }
}
@mixin tabletPortrait {
  @media only screen and (min-width: #{$tablet}) and (max-width: #{$tabletdw2}) and (orientation:portrait) {
    @content;
  }
}
@mixin ishighResolution {
  @media (max-width: #{$highResolution}) {
    @content;
  }
}
@mixin highResolution {
  @media (min-width: #{$highResolution}) {
    @content;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}
