//Core files
@import "../../node_modules/bootstrap/scss/functions";
// @import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/bootstrap";

@import "./components/fonts";

@import "variables";

@import "./components/reboot";
@import "./components/backgrounds";
@import "./components/badge";
@import "./components/buttons";
@import "./components/breadcrumb";
@import "./components/card";
@import "./components/dropdown";
@import "./components/nav";
@import "./components/table";
@import "./components/pagination";
@import "./components/progress";
@import "./components/buttons";
@import "./components/scrollbar";
@import "./components/tooltip";
@import "./chips.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: white;
}
.login .logo-img {
  max-width: 130px;
  margin-bottom: 12px;
  margin-top: -56px;
}
.right-content {
  height: 100%;
  display: flex;
  justify-content: center;
  /* justify-content: start; */
  max-width: 400px;
  margin: auto;
  flex-direction: column;
}

.login-select {
  // border-radius: px !important;
  width: 75px !important;
}

.search-input {
  width: 300px !important;
}

.dropdown-container {
  position: absolute;
  top: 110%;
  background-color: white;
  min-width: 300px;
  border-radius: 8px;
  max-height: 165px;
  overflow: hidden;
  z-index: 1;

  border: 1px solid #ced4da;
}

.dropdown-container {
  > div > div {
    font-size: 12px;
    padding: 2px 5px;
  }
  > div > div:hover {
    background-color: $primary;
    color: white !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.login-btn-width {
  width: 100px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.phone-no {
  padding-left: 87px !important;
}
.value-container {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.w-700 {
  font-weight: 700;
}

:not(input):not(textarea):not(.link) {
  text-transform: capitalize;
}

#content,
#content-collapse {
  width: calc(100% - 270px);
  padding: 20px 20px 20px 20px;
  height: calc(100vh);
  overflow: auto;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  overflow-x: hidden;
}
#content-collapse {
  width: calc(100% - 70px);
}
.header {
  background-color: #ffffff;
  padding: 10px;
  font-size: 27px;
  color: #555b6d;
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}

.header img {
  height: 50px;
  width: 50px;
}
.header .logo-txt {
  font-size: 22px;
  margin-left: 20px;
  color: #555b6d;
  font-weight: 700;
}
.header .menu-txt {
  font-size: 15px;
  font-weight: 500;
  margin-left: 20px;
  color: #555b6d;
}
.navbar-right {
  position: absolute;
  right: 10px;
  top: 0;
  padding: 10px 0;
}

#sidebar,
#sidebar-collapse {
  width: 270px;
  background: #ffffff;
  position: fixed;
  left: 0;
  height: 100vh;
  color: #495075;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}
#sidebar-collapse {
  width: 70px;
  transition: all 0.3s;
}

#sidebar ul,
#sidebar-collapse ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
#sidebar .module-list {
  overflow: auto;
  height: calc(100vh - 147px);
}
#sidebar-collapse .module-list {
  overflow: auto;
  height: calc(100vh - 195px);
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
#sidebar > div > ul > li,
#sidebar-collapse > div > ul > li {
  margin: 0px 7px;
}
li::marker {
  display: none !important;
}
.dropdown-sidebar li > ::marker {
  display: none;
}
#sidebar ul li a,
#sidebar ul li .dropdown-sidebar,
#sidebar-collapse ul li a,
#sidebar-collapse ul li .dropdown-sidebar {
  display: flex;
  align-items: center;
  padding: 14px;
  color: $gray-300;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.4s;
  border-radius: 3px;
  cursor: pointer;
}
#sidebar ul li.active > a,
#sidebar ul li.active .dropdown-sidebar,
#sidebar-collapse ul li.active > a,
#sidebar-collapse ul li.active .dropdown-sidebar {
  color: #383c40 !important;
  background-color: #f3f8fb;
}

#sidebar ul li > a:hover,
#sidebar ul li .dropdown-sidebar:hover,
#sidebar-collapse ul li > a:hover,
#sidebar-collapse ul li .dropdown-sidebar:hover {
  color: #383c40;
}

.sidemenu-list ul {
  padding: 0px;
}

.sidemenu-list {
  width: 280px;
}
.sidemenu-list ul li.active div {
  background-color: $white;
}
.sidemenu-list ul li div:hover {
  background-color: $white;
}
.sidemenu-list ul li div {
  display: block;
  cursor: pointer;
  padding: 0.8rem 0.7rem;
  margin-right: 50px;
  margin-bottom: 10px;
  position: relative;
  font-size: 15px;
  transition: all 0.4s;
  border-radius: 6px;
}

.w-400 {
  font-weight: 400;
}
.page-title {
}

.section-border {
  border: 2px solid $gray-200;
  border-radius: 6px;
}

.sections-menu {
  list-style: none;
  padding: 0px;
  padding-right: 30px;
}
.sections-menu li {
  margin-bottom: 10px;
  color: $gray-300;
  cursor: pointer;
}
.sections-menu li.active,
.sections-menu li:hover {
  color: $primary;
}

.section-card {
  width: 300px;
}

.text-gray-200 {
  color: $gray-200;
}
.text-gray-300 {
  color: $gray-300;
}
