* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html {
  font-size: 62.5%;
} 10px */

:root {
  --clr-400: rgb(42, 44, 59);
  --clr-text: rgb(73, 80, 87);
  --clr-white: #fff;
  --clr-black: #000;

  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

body {
  /* background: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.25rem;
  color: var(--clr-text);
  overflow-y: auto;
  height: 100vh;
  position: relative; */
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Poppins", sans-serif; */
  font-family: "IBM Plex Sans", sans-serif;
}

/* p {
  margin-bottom: 1.25rem;
  color: var(--clr-text);
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.75rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
  color: var(--clr-text);
}

ul {
  list-style-type: none;
  padding: 0;
}

.img {
  display: block;
  width: 100%;
  object-fit: cover;
} */

/* buttons */
/* .btn {
  cursor: pointer;
  color: var(--clr-white);
  background: var(--clr-400);
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 0.375rem 0.9rem;
  font-size: 1.6rem;
  text-transform: capitalize;
  display: inline-block;
} */
